import Layout from '@/layout/index';

const router = [
  {
    path: '/setting',
    component: Layout,
    redirect: '/setting/chat',
    meta: { auth: true, title: '运营设置', name: 'settingMgr', icon: 'saasicon_Settings_img' },
    children: [
      // {
      //   path: 'info',
      //   component: () => import('@/views/SettingModule/setting.vue'),
      //   meta: { auth: true, title: '开发设置', name: 'settingInfo', activeMenu: '/setting/info' }
      // }, // 开发设置 - 总览页隐藏
      {
        path: 'chat',
        component: () => import('@/views/SettingModule/chat'),
        name: 'chat',
        meta: { auth: true, title: '聊天设置', name: 'chatMgr', activeMenu: '/setting/chat' }
      },
      {
        path: 'safeScreen',
        component: () => import('@/views/LiveModule/Brands/safeScreenSet'),
        name: 'safeScreen',
        meta: {
          auth: true,
          title: '防录屏设置',
          name: 'safeScreen',
          activeMenu: '/setting/safeScreen',
          auth_key: 'player_config'
        }
      },
      {
        path: '/setting/dev',
        component: () => import('@/views/SettingModule/setting'),
        name: 'dev',
        meta: { title: '开发设置', icon: '', name: 'devMgr', activeMenu: '/setting/dev' }
      },
      {
        path: 'logo',
        component: () => import('@/views/SettingModule/logo'),
        name: 'logo',
        meta: { auth: true, title: '控制台标识', name: 'logoMgr', activeMenu: '/setting/logo' }
      },
      {
        path: 'function',
        component: () => import('@/views/LiveModule/planFunction'),
        name: 'function',
        meta: {
          auth: true,
          title: '功能配置',
          name: 'functionMgr',
          activeMenu: '/setting/function'
        }
      },
      {
        path: 'brand',
        component: () => import('@/views/SettingModule/setLiving'),
        name: 'brand',
        meta: { auth: true, title: '直播间设计器', name: 'livingMgr', activeMenu: '/setting/brand' }
      },
      {
        path: 'protocol',
        component: () => import('@/views/SettingModule/protocol'),
        name: 'protocol',
        meta: {
          auth: true,
          title: '合规风险设置',
          name: 'protocolMgr',
          activeMenu: '/setting/protocol',
          auth_key: 'watch.viewing_protocol',
          appCls: 'std-app'
        }
      },
      {
        path: 'player',
        component: () => import('@/views/LiveModule/Brands/playerSet'),
        name: 'player',
        meta: {
          auth: true,
          title: '播放器设置',
          name: 'playerMgr',
          activeMenu: '/setting/player',
          auth_key: 'player_config'
        }
      },
      {
        path: 'tagset',
        component: () => import('@/views/SettingModule/tagSet/index.vue'),
        name: 'tagset',
        meta: { auth: true, title: '标签设置', name: 'tagset', activeMenu: '/setting/tagset' }
      },
      {
        path: '/dev/add',
        component: () => import('@/views/SettingModule/Development/appInfo'),
        meta: {
          auth: true,
          title: '新增应用',
          name: 'devAdd',
          activeMenu: '/setting/dev',
          action: 'add'
        },
        hidden: true
      },
      {
        path: '/dev/:appId(\\d+)',
        component: () => import('@/views/SettingModule/Development/appInfo'),
        meta: {
          auth: true,
          title: '应用详情',
          name: 'devModify',
          activeMenu: '/setting/dev',
          action: 'detail'
        },
        hidden: true
      },
      {
        path: '/dev/callback',
        component: () => import('@/views/SettingModule/Development/callback'),
        meta: { auth: true, title: '回调设置', name: 'devCallback', activeMenu: '/setting/dev' },
        hidden: true
      },
      {
        path: 'logo/:str(\\d+)',
        component: () => import('@/views/SettingModule/logo'),
        meta: { auth: true, title: '控制台标识', name: 'logoSetting', activeMenu: '/setting/logo' },
        hidden: true
      },
      {
        path: 'viewer',
        component: () => import('@/views/MaterialModule/viewer'),
        meta: { auth: true, title: '观众设置', name: 'viewerMgr', auth_key: 'white_list' }
      },
      {
        path: 'channel',
        component: () => import('@/views/MaterialModule/channel'),
        meta: { auth: true, title: '渠道设置', name: 'channelSetting', auth_key: 'watch.channel' }
      },
      {
        path: 'commonParams',
        component: () => import('@/views/MaterialModule/commonParams'),
        meta: { auth: true, title: '通用字段', name: 'commonParams' }
      },
      {
        path: 'officialAccAuth',
        component: () => import('@/views/MaterialModule/officialAccAuth'),
        meta: { auth: true, title: '公众号授权', name: 'officialAccAuth' }
      },
      {
        path: 'webinarBusinessField',
        component: () => import('@/views/SettingModule/webinarBusinessField'),
        meta: {
          auth: true,
          title: '直播业务字段',
          name: 'webinarBusinessField',
          auth_key: 'webinar_custom_fields'
        }
      },
      {
        path: 'setVipTag',
        component: () => import('@/views/MaterialModule/setVipTag'),
        meta: { auth: true, title: '观众等级标签', name: 'setVipTag' }
      }
    ]
  }
];
export default router;
