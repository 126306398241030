import EmbedLayout from '@/layout/embedLayout';
const router = [
  {
    path: '/embed',
    component: EmbedLayout,
    meta: { auth: false, title: '嵌入页面', name: 'embedLayout' },
    hidden: true,
    children: [
      {
        path: 'live/list',
        component: () => import('@/views/LiveModule/list'),
        meta: { title: '直播列表', name: 'liveList' },
        hidden: true
      },
      {
        path: 'live/edit',
        component: () => import('@/views/LiveModule/edit'),
        meta: { title: '创建直播', name: 'liveCreate', webinarType: 'live' },
        hidden: true
      },
      {
        path: 'live/edit/:id',
        component: () => import('@/views/LiveModule/edit'),
        meta: { title: '编辑直播', name: 'liveEdit', webinarType: 'live' },
        hidden: true
      },
      {
        path: 'live/vodEdit/:id',
        component: () => import('@/views/LiveModule/edit'),
        meta: { title: '编辑点播', name: 'vodEdit', webinarType: 'vod' },
        hidden: true
      },
      {
        path: 'live/vodEdit',
        component: () => import('@/views/LiveModule/edit'),
        meta: { title: '创建点播', name: 'vodCreate', webinarType: 'vod' },
        hidden: true
      },
      {
        path: 'live/timeEdit',
        component: () => import('@/views/LiveModule/edit'),
        meta: { title: '定时直播', name: 'liveTimeCreate', webinarType: 'time' },
        hidden: true
      },
      {
        path: 'live/timeEdit/:id',
        component: () => import('@/views/LiveModule/edit'),
        meta: { title: '编辑定时直播', name: 'liveTimeEdit', webinarType: 'time' },
        hidden: true
      },
      {
        path: 'live/detail/:str(\\d+)',
        component: () => import('@/views/LiveModule/detail'),
        meta: { title: '直播详情', name: 'liveDetail', webinarType: 'live' },
        hidden: true
      },
      {
        path: 'live/viewerRules/:str(\\d+)',
        component: () => import('@/views/LiveModule/viewerRules'),
        meta: { title: '观看限制', name: 'viewerRules' },
        hidden: true
      },
      {
        path: 'live/roleInvitation/:str(\\d+)',
        component: () => import('@/views/LiveModule/roleInvitation'),
        meta: { title: '角色权限', name: 'roleInvitation' },
        hidden: true
      },
      {
        path: 'live/planFunction/:str(\\d+)',
        component: () => import('@/views/LiveModule/planFunction'),
        meta: { title: '功能配置', name: 'planFunction' },
        hidden: true
      },
      {
        path: 'live/virtual/:str(\\d+)',
        component: () => import('@/views/LiveModule/virtual'),
        meta: { title: '虚拟人数', name: 'virtual' },
        hidden: true
      },
      {
        path: 'live/warm/:str(\\d+)',
        component: () => import('@/views/LiveModule/warm'),
        meta: { title: '暖场视频', name: 'warm' },
        hidden: true
      },
      {
        path: 'live/embedCard/:str(\\d+)',
        component: () => import('@/views/LiveModule/embedCard'),
        meta: { title: '嵌入方式', name: 'embedCard' },
        hidden: true
      },
      {
        path: 'live/livingSet/:str(\\d+)',
        component: () => import('@/views/LiveModule/Brands/livingSetting'),
        meta: { title: '直播间设计器', name: 'livingSetting' },
        hidden: true
      },
      {
        path: 'live/safeScreenSet/:str(\\d+)',
        component: () => import('@/views/LiveModule/Brands/safeScreenSet'),
        meta: { title: '防录屏设置', name: 'safeScreenSet' },
        hidden: true
      },
      {
        path: 'live/playerSet/:str(\\d+)',
        component: () => import('@/views/LiveModule/Brands/playerSet'),
        meta: { title: '播放器设置', name: 'playerSet' },
        hidden: true
      },

      {
        path: 'live/word/:str(\\d+)',
        component: () => import('@/views/MaterialModule/word'),
        meta: { title: '文档', name: 'word' },
        hidden: true
      },
      {
        path: 'live/videoSet/:str(\\d+)',
        component: () => import('@/views/LiveModule/MaterialSet/videoSet'),
        meta: { title: '插播文件', name: 'videoSet' },
        hidden: true
      },
      {
        path: 'live/winnerList/:str(\\d+)',
        component: () => import('@/views/LiveModule/MaterialSet/winnerList'),
        meta: { title: '中奖用户', name: 'winnerList' },
        hidden: true
      },
      {
        path: 'live/prizeList/:str(\\d+)',
        component: () => import('@/views/LiveModule/MaterialSet/prizeList'),
        meta: { title: '抽奖', name: 'prizeList' },
        hidden: true
      },
      {
        path: 'live/prizeSet/:str(\\d+)',
        component: () => import('@/views/LiveModule/MaterialSet/prizeSet'),
        meta: { title: '创建抽奖', name: 'prizeCreat' },
        hidden: true
      },
      {
        path: 'live/question/:str(\\d+)',
        component: () => import('@/views/LiveModule/MaterialSet/question'),
        meta: { title: '问卷', name: 'question' },
        hidden: true
      },
      {
        path: 'live/addQuestion',
        component: () => import('@/views/LiveModule/MaterialSet/components/addQuestion'),
        meta: { title: '创建问卷', name: 'addQuestion' },
        hidden: true
      },
      {
        path: 'live/exam/:str(\\d+)',
        component: () => import('@/views/LiveModule/Exam/main'),
        meta: { title: '快问快答', name: 'exam' },
        hidden: true
      },
      {
        path: 'live/addExam',
        component: () => import('@/views/LiveModule/Exam/components/addExam'),
        meta: { title: '创建', name: 'addExam' },
        hidden: true
      },
      {
        path: 'live/productSet/:str(\\d+)',
        component: () => import('@/views/MaterialModule/Products/index.vue'),
        meta: { title: '商品', name: 'productSet' },
        hidden: true
      },
      {
        path: 'live/couponData/:str(\\d+)',
        component: () => import('@/views/MaterialModule/Products/viewCoupon.vue'),
        meta: { title: '优惠券数据', name: 'couponData' },
        hidden: true
      },
      {
        path: 'live/pushCard/:str(\\d+)',
        component: () => import('@/views/LiveModule/PushCard/main'),
        meta: { title: '推屏卡片', name: 'pushCard' },
        hidden: true
      },
      {
        path: 'live/addPushCard',
        component: () => import('@/views/LiveModule/PushCard/components/addCard'),
        meta: { title: '创建', name: 'addPushCard' },
        hidden: true
      },
      {
        path: 'live/clickPushCard',
        component: () => import('@/views/LiveModule/PushCard/components/clickCard'),
        meta: { title: '点击人数', name: 'clickPushCard' },
        hidden: true
      },
      {
        path: 'live/gift/:str(\\d+)',
        component: () => import('@/views/LiveModule/MaterialSet/giftSet'),
        meta: { title: '礼物', name: 'gift' },
        hidden: true
      },
      {
        // 列表
        path: 'live/InteractReward/:str(\\d+)',
        component: () => import('@/views/LiveModule/InteractReward/index.vue'),
        meta: { auth: true, title: '互动有礼', name: 'InteractReward', activeMenu: '/live/list' },
        hidden: true
      },
      {
        // 列表
        path: 'live/WatchReward/:str(\\d+)',
        component: () => import('@/views/LiveModule/WatchReward/index.vue'),
        meta: { auth: true, title: '观看有礼', name: 'WatchReward', activeMenu: '/live/list' },
        hidden: true
      },
      {
        // 列表
        path: 'live/CashPacket/:str(\\d+)',
        component: () => import('@/views/LiveModule/CashPacket/list.vue'),
        meta: { auth: true, title: '现金红包', name: 'CashPacket', activeMenu: '/live/list' },
        hidden: true
      },
      {
        // 创建、编辑、复制共用
        path: 'live/InteractiveRewardSetting/:str(\\d+)',
        component: () => import('@/views/LiveModule/InteractReward/setting/index.vue'),
        meta: {
          auth: true,
          title: '互动有礼',
          name: 'InteractiveRewardSetting',
          activeMenu: '/live/list'
        },
        hidden: true
      },
      {
        // 领奖用户列表
        path: 'live/InteractiveRewardUserList/:str(\\d+)',
        component: () => import('@/views/LiveModule/InteractReward/UserList.vue'),
        meta: {
          auth: true,
          title: '互动有礼',
          name: 'InteractiveRewardUserList',
          activeMenu: '/live/list'
        },
        hidden: true
      },
      {
        path: 'live/playback/:str(\\d+)',
        component: () => import('@/views/LiveModule/PlayBack/list'),
        meta: { title: '回放管理', name: 'playback' },
        hidden: true
      },
      {
        path: 'live/playback/:str(\\d+)/group/:switch_id(\\d+)',
        component: () => import('@/views/LiveModule/PlayBack/groupList'),
        meta: {
          title: '小组视频',
          name: 'playbackGroupList'
        },
        hidden: true
      },
      {
        path: 'live/recordplayback/:str(\\d+)',
        component: () => import('@/views/LiveModule/PlayBack/list'),
        meta: { title: '点播管理', name: 'recordplayback' },
        hidden: true
      },
      {
        path: 'live/publishplayback/:str(\\d+)',
        component: () => import('@/views/LiveModule/PlayBack/list'),
        meta: { title: '视频管理', name: 'publishplayback' },
        hidden: true
      },
      {
        path: 'live/vodreset/:str(\\d+)',
        component: () => import('@/views/LiveModule/PlayBack/vodreset'),
        meta: { title: '课件重制', name: 'vodreset' },
        hidden: true
      },
      {
        path: 'live/reportsData/:str(\\d+)',
        component: () => import('@/views/LiveModule/Data/reportsData'),
        meta: { title: '数据报告', name: 'reportsData' },
        hidden: true
      },
      {
        path: 'live/interactionData/:str(\\d+)',
        component: () => import('@/views/LiveModule/Data/interactData'),
        meta: { title: '互动统计', name: 'interactionData' },
        hidden: true
      },
      {
        path: 'live/userData/:str(\\d+)',
        component: () => import('@/views/LiveModule/Data/userData'),
        meta: { title: '用户统计', name: 'userData' },
        hidden: true
      },
      {
        path: 'live/interactionDetail',
        component: () => import('@/views/LiveModule/Data/interactDetail'),
        meta: { title: '查看数据', name: 'interactionDetail' },
        hidden: true
      },
      {
        path: 'live/interactionDetail/invitedDataInfo',
        component: () => import('@/views/LiveModule/Data/invitedDataInfo'),
        meta: { title: '邀请明细', name: 'invitedDataInfo' },
        hidden: true
      },
      {
        path: 'live/lookSingleQuestion/:str(\\d+)',
        component: () => import('@/views/LiveModule/Data/lookSingleQuestion'),
        meta: { title: '查看问卷详情', name: 'lookSingleQuestion' },
        hidden: true
      },
      {
        path: 'live/lookSingleExam/:str(\\d+)',
        component: () => import('@/views/LiveModule/Data/lookSingleExam'),
        meta: { title: '查看快问快答明细', name: 'lookSingleExam' },
        hidden: true
      },
      //互动
      {
        path: 'interactive/products',
        component: () => import('@/views/MaterialModule/Products/index'),
        meta: { title: '商品', name: 'products' },
        hidden: true
      },
      {
        path: 'interactive/addProduct',
        component: () => import('@/views/MaterialModule/Products/create'),
        meta: { title: '创建商品', name: 'createproducts' },
        hidden: true
      },
      {
        path: 'interactive/addCoupon',
        component: () => import('@/views/MaterialModule/Products/createCoupon'),
        meta: { title: '创建优惠券', name: 'createCoupon' },
        hidden: true
      },
      {
        path: 'interactive/addProduct/:goods_id',
        component: () => import('@/views/MaterialModule/Products/create'),
        meta: { title: '编辑商品', name: 'editproducts' },
        hidden: true
      },
      {
        path: 'interactive/editCoupon/:couponId',
        component: () => import('@/views/MaterialModule/Products/createCoupon'),
        meta: { title: '编辑优惠券', name: 'editCoupon' },
        hidden: true
      },
      {
        path: 'interactive/viewCoupon/:couponId',
        component: () => import('@/views/MaterialModule/Products/viewCoupon'),
        meta: { title: '商品', name: 'viewCoupon' },
        hidden: true
      },
      {
        path: 'interactive/copyCoupon/:couponId',
        component: () => import('@/views/MaterialModule/Products/createCoupon'),
        meta: { title: '复制优惠券', name: 'copyCoupon' },
        hidden: true
      },
      {
        path: 'live/AI/chat/list/:id(\\d+)',
        component: () => import('@/views/AIModule/chat/list'),
        meta: {
          auth: true,
          title: 'AI聊天',
          name: 'AIChatListLive'
        },
        hidden: true
      },
      {
        path: 'live/AI/chat/create',
        component: () => import('@/views/AIModule/chat/edit'),
        meta: {
          auth: true,
          title: '创建',
          name: 'AIChatCreateLive'
        },
        hidden: true
      },
      {
        path: 'live/AI/chat/edit/:id(\\d+)',
        component: () => import('@/views/AIModule/chat/edit'),
        meta: {
          auth: true,
          title: '编辑',
          name: 'AIChatEditLive'
        },
        hidden: true
      }
    ]
  }
];
export default router;
