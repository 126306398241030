/*
 * @Description: store
 * @Author: yincece
 */

import { safeJsonParse } from '@/utils/utils';
import Vue from 'vue';
import Vuex from 'vuex';
Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    // 开发者权限
    developer: {},
    permission: {},
    permissionValue: {}, //admin权限设置的值
    childPermission: {}, // 子账号权限

    // 当前活动下权限
    webinarPermission: {}
  },
  mutations: {
    setDeveloper(state, developer) {
      state.developer = developer;
    },
    setPermission(state, permissions) {
      state.permission = permissions;
    },
    setPermissionValue(state, permissions) {
      state.permissionValue = permissions;
    },
    setWebinarPermission(state, permissions) {
      state.webinarPermission = permissions;
    }
  }
});

export default store;
